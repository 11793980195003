<template>
  <v-col
    col="12"
    sm="12"
  >
    <v-toolbar
      class="pl-0"
      flat
      dense
      color="accent"
    >
      <!--Title Bar-->
      <v-toolbar-title>
        <title-bar
          :title-value="institutionsStore.institution.name"
          :reduce-x="1"
        />
      </v-toolbar-title>

      <v-spacer />
      
      <institution :section="2" />
    </v-toolbar>

    
    <v-card
      class="px-4"
      flat
      tile
      color="accent"
    >
      <v-row
        class="pb-6"
      >
        <v-col
          class="pt-2 pb-0"
          col="12"
          sm="12"
        >
          <text-display
            v-if="institutionsStore.institution.jurisdiction"
            :display-string="institutionsStore.institution.jurisdiction.name"
            :label="'Jurisdiction'"
            side-label
          />
        </v-col>
      </v-row>
    </v-card>

    <departments />
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import { mixin } from '@/mixins/mixin.js';
import TitleBar from '@/components/TitleBar';
import TextDisplay from '@/components/TextDisplay';
import Departments from '@/components/Departments';
import Institution from '@/components/Institution';

export default {
  name: 'InstitutionDetail',
  components: {
    TitleBar,
    TextDisplay,
    Departments,
    Institution,
  },
  mixins: [mixin],
  data() {
    return {

    }
  },
  computed: {
    ...mapState({
      institutionsStore: state => state.institutionsStore,
    }),
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {

    },   
  }
}
</script>

<style scoped>

</style>